<template>
  <div>
  <floating-menu :event-id="eventId"></floating-menu>
  <b-row>
    <b-col cols="12">
      <event-participant-table v-if="totalRecords<=500 && totalRecords != null" />
      <event-participant-table-big-data v-if="totalRecords>500 && totalRecords != null" />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EventParticipantTable from './EventParticipantTable.vue'
import EventParticipantTableBigData from './EventParticipantTableBigData.vue'
import router from '@/router'
import FloatingMenu from './FloatingMenu.vue'


export default {
  components: {
    BRow,
    BCol,
    FloatingMenu,
    EventParticipantTable,
    EventParticipantTableBigData
  },
  data() {
    return {
      eventId: router.currentRoute.params.id,
      totalRecords: null
    }
  },
  methods: {
    fetchData() {
      this.$http.get('/admin/v1/events/' + this.eventId + '/registers-count')
        .then(res => {
          const dataCount = res.data
          const messageCount = dataCount.message

          if(!dataCount.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: messageCount,
              },
            })
          }
          else {
            this.totalRecords = dataCount.data
          }
     })

      
    },
  },
  created() {
    this.fetchData()
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>