<template>
  <b-card>

    <h4 v-if="currentObject.event_name" class="mb-2">
      Event Participants <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <div class="row">
      <div class="col-sm-6">
        <div class="float-left">
          <b-button-group>
            <b-dropdown variant="primary" right split text="Actions" v-if="$can('add', 'event_participants') || $can('view', 'event_participants')">
              <b-dropdown-item @click="add()" v-if="$can('add', 'event_participants')" :disabled="isLoading">
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  /><span>Add</span>
              </b-dropdown-item>
              
              <b-dropdown-item @click="verifySelected()" v-if="$can('edit', 'event_participants')" :disabled="isLoading">
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  /><span>Verify Selected</span>
              </b-dropdown-item>
              
              <b-dropdown-item @click="declineSelected()" v-if="$can('edit', 'event_participants')" :disabled="isLoading">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  /><span>Decline Selected</span>
              </b-dropdown-item>

              <b-dropdown-item @click="downloadExcel()" v-if="$can('view', 'event_participants')" :disabled="isLoading">
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  /><span>Export Excel</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadQR()" v-if="$can('edit', 'event_participants')" :disabled="isLoading">
                <feather-icon
                    icon="CameraIcon"
                    class="mr-50"
                  /><span>QR Codes</span></b-dropdown-item>
              <b-dropdown-item @click="downloadNameTags()" v-if="$can('edit', 'event_participants')" :disabled="isLoading">
                <feather-icon
                    icon="BookmarkIcon"
                    class="mr-50"
                  /><span>Name Tags</span>
              </b-dropdown-item>
              <b-dropdown-item @click="importExcel()" v-if="$can('add', 'event_participants')" :disabled="isLoading">
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  /><span>Import</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </div>

        <div class="ml-1 float-left participant-filter">
          <v-select
            v-model="participantFilter"
            :options="filterValues"
            :reduce="val => val.value"
            :clearable="false"
            input-id="participants"
            placeholder="Pick a filter"
            @input="onFilterChange"
          />
        </div>

      </div>
      <div class="col-sm-6 pt-1 pt-md-0 pb-md-0">
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                name="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

      </div>
    </div>


    <!-- table -->
    <div v-if="!rows || !allRows || isLoadingTable || !currentObject.event_name" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <div class="event-participant-table">
      <vue-good-table
      v-if="rows && !isLoadingTable"
      @on-selected-rows-change="selectionChanged"
      :columns="columns"
      :rows="rows"
      ref="participants"
      :select-options="{ 
        enabled: true 
        }"
      :sort-options="{
        enabled: true,
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'salutation'"
          class="text-nowrap"
        > 
          {{ (!props.row.user_profile) ? props.row.user.salutation + ". " : props.row.user_profile.registered_salutation+". " }}
        </span>

        <span
          v-if="props.column.field === 'first_name'"
          class="text-nowrap"
        > 
          {{ (!props.row.user_profile) ? props.row.user.name : props.row.user_profile.registered_name }}
        </span>

        <span
          v-if="props.column.field === 'last_name'"
          class="text-nowrap"
        > 
          {{ (!props.row.user_profile) ? props.row.user.last_name : props.row.user_profile.registered_last_name }}
        </span>

        <!-- Column: Email -->
        <!-- <span
          v-if="props.column.field === 'email'"
          class="text-nowrap"
        > {{ (props.row.user) ? props.row.user.email : '-' }}
        </span> -->

        <!-- Column: Phone -->
        <!-- <span
          v-if="props.column.field === 'phone'"
          class="text-nowrap"
        > {{ (props.row.profile) ? props.row.profile.phone : '-' }}
        </span> -->

        <!-- Column: Office -->
        <!-- <span
          v-if="props.column.field === 'company'"
          class="text-nowrap"
        >
        </span> -->

        <!-- Column: Home address -->
        <span
          v-if="props.column.field === 'home_address'"
          class="text-nowrap"
        > {{ (props.row.profile) ? props.row.profile.home_address : '-' }}
        </span>



        <!-- Column: Verified at -->
        <span
          v-if="props.column.field === 'verified_at_formatted'"
          class="text-nowrap"
        >
          <b-badge :variant="statusVariant(props.row.verified_at, props.row.declined_at)">
            {{ labelVariant(props.row.verified_at, props.row.declined_at)  }}
          </b-badge>
          <div v-if="props.row.user.deleted_at != null">
            <b-badge :variant="light-danger" >
              User deleted at {{ props.row.user.deleted_at }}
            </b-badge>
          </div>
        </span>

        <span
          v-if="props.column.field === 'transfer_proofs'"
          class="text-nowrap"
        > 
        <a v-if="props.row.transfer_proof" :href=props.row.transfer_proof target="_blank">View Image</a>
        </span>

        <!-- Column: Paid at -->
        <!-- <span
          v-if="props.column.field === 'paid_at'"
          class="text-nowrap"
        >
        </span> -->

        <!-- Column: Checked in at -->
        <!-- <span
          v-if="props.column.field === 'checked_in_at'"
          class="text-nowrap"
        > 
        </span> -->

        <!-- Column: Updated at -->
        <!-- <span
          v-if="props.column.field === 'updated_at'"
          class="text-nowrap"
        >
        </span> -->

        <!-- column QR -->
        <span
            v-if="props.column.field === 'qr_access'"
            class="text-nowrap">
            <a @click="qrModal(props.row)">
              <qrcode-vue :value="props.row.user.email"></qrcode-vue>
            </a>
          </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'updated_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.updated_by) ? props.row.updated_by.name : props.row.created_by.name }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="verify(props.row.id, props.row.originalIndex)" v-if="$can('edit', 'event_participants') && props.row.verified_at === null && props.row.declined_at === null">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span>Verify</span>
              </b-dropdown-item>
              <b-dropdown-item @click="decline(props.row.id, props.row.originalIndex)" v-if="$can('edit', 'event_participants')  && props.row.verified_at === null && props.row.declined_at === null">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Decline</span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadNametagSingle(props.row)" v-if="$can('view', 'event_participants')">
                <feather-icon
                  icon="BookmarkIcon"
                  class="mr-50"
                />
                <span>Download Nametag</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editModal(props.row)" v-if="$can('edit', 'event_participants')">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="detailModal(props.row)" v-if="$can('view', 'event_participants')">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Detail</span>
              </b-dropdown-item>
              <!--
              <b-dropdown-item @click="removeModal(props.row.user_id)" v-if="$can('delete', 'event_participants')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
              -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </div>

    <div class="mt-2">
      <div style="float: left">
        <b-button size="md" variant="outline-primary" @click="viewAllEvents()" v-if="$can('view', 'events')">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showImportModal"
    >
      <template #modal-header="{}">
        <h5>Import {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm || isLoading || isLoadingTable" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-alert variant="success" show v-if="importModal.successMessage !== null" class="p-1 mb-1">{{ importModal.successMessage }}</b-alert>
      
      <b-alert variant="danger" show v-if="importModal.errorMessages !== null && importModal.errorMessages.length > 0" class="p-1 mb-1">
        <ul>
          <li v-for="message in importModal.errorMessages" :key="message">
            {{ message }}
          </li>
        </ul>
      </b-alert>

      <b-form v-if="!isLoadingForm && !isLoadingTable && !isLoading">
        <b-form-group>
          <label for="user">File (.xlsx):</label>
            <b-form-file v-model="importFile" accept=".xlsx"></b-form-file>
        </b-form-group>
        <p class="mt-1"><small><a href="#" @click="sampleExcel()">Download excel file format</a></small></p>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="saveImport()" :disabled="importFile === null || isLoadingForm || isLoading || isLoadingTable">
          {{ (!isLoadingForm && !isLoadingTable && !isLoading) ? "Save" : "Loading..." }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>Add {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="user">User:</label>
            <v-select
              v-model="currentObject.user_id"
              :options="users"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user"
              placeholder="Pick a user"
              v-if="users !== null"
            />
        </b-form-group>

        <div v-for="(item, index) in currentFields" :key="index">
          <b-form-group>
            <label>{{ item.name }}</label>
              <b-form-select
                v-model="currentObject.additional_fields[index].value"
                :options="currentObject.additional_fields[index].attributes.options"
                :placeholder="currentObject.additional_fields[index].placeholder"
                v-if="item.type == 'options' && currentObject.additional_fields[index].attributes.options !== null"
              />
              <b-form-input
                v-model="currentObject.additional_fields[index].value"
                :placeholder="currentObject.additional_fields[index].placeholder"
                name="text"
                class="d-inline-block"
                v-if="item.type == 'text'"
              />
          </b-form-group>
        </div>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete this item?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-edit"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showEditModal"
    >
      <template #modal-header="{}">
        <h5>Edit Additional Data ({{ currentUserObject.user.email }})</h5>
      </template>

      <b-row v-if="currentUserObject !== null" >
        <b-col cols="12" v-if="currentUserObject.additional_registration_data.length > 0">

          <b-form>
            <div v-for="(item, index) in currentUserObject.additional_registration_data" :key="index">
              <b-form-group>
                <label>{{ item.field_name }}</label>
                  <b-form-select
                    v-model="currentUserObject.additional_registration_data[index].value"
                    :options="item.details.attributes.options"
                    :placeholder="item.details.placeholder"
                    v-if="item.details.type == 'options' && item.details.attributes.options !== null"
                  />
                  <b-form-input
                    v-model="currentUserObject.additional_registration_data[index].value"
                    :placeholder="item.details.placeholder"
                    name="text"
                    class="d-inline-block"
                    v-if="item.details.type == 'text'"
                  />
              </b-form-group>
            </div>
          </b-form>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="saveAdditionalData()">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
          </b-button>
        </div>
      </template>


    </b-modal>

    <b-modal
      id="modal-detail"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDetailModal"
    >
      <template #modal-header="{}">
        <h5>Profile</h5>
      </template>

      <b-row>
        <b-col cols="12">
          <div v-if="currentUserObject === null" class="text-center mt-2">
            <div class="demo-spacing-0">
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body">
                  This user hasn't supplied any details
                </div>
              </b-alert>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <b-row v-if="currentUserObject !== null" >
        <b-col cols="6">
          <h5>First Name</h5>
          <p>{{ currentUserObject.user.name }}</p>

          <h5>Last Name</h5>
          <p>{{ (currentUserObject.user.last_name) ? currentUserObject.user.last_name : '-' }}</p>

          <h5>Salutation</h5>
          <p>{{ currentUserObject.user.salutation }}</p>

          <h5>Email</h5>
          <p>{{ (currentUserObject.user.email) ? currentUserObject.user.email : '-' }}</p>

          <h5>Phone</h5>
          <p>{{ (currentUserObject.profile.phone) ? currentUserObject.profile.phone : '-' }}</p>

          <h5>Home Address</h5>
          <p>{{ (currentUserObject.profile.home_address) ? currentUserObject.profile.home_address : '-' }}</p>

          <h5>Office Phone</h5>
          <p>{{ (currentUserObject.profile.company_phone) ? currentUserObject.profile.company_phone : '-' }}</p>

          <h5>Office Email</h5>
          <p>{{ (currentUserObject.profile.company_email) ? currentUserObject.profile.company_email : '-' }}</p>
        </b-col>

        <b-col cols="6">
          <h5>Office Name</h5>
          <p>{{ (currentUserObject.profile.company) ? currentUserObject.profile.company : '-' }}</p>

          <h5>Office Address</h5>
          <p>{{ (currentUserObject.profile.company_address) ? currentUserObject.profile.company_address : '-' }}</p>

          <h5>Office City</h5>
          <p>{{ (currentUserObject.profile.company_city) ? currentUserObject.profile.company_city.name + ", " + currentUserObject.profile.company_city.state.name + ", " + currentUserObject.profile.company_city.state.country.name : '-' }}</p>

          <h5>Brand Name</h5>
          <p>{{ (currentUserObject.profile.brand_name) ? currentUserObject.profile.brand_name : '-' }}</p>

          <h5>Office Size</h5>
          <p>{{ (currentUserObject.profile.company_size) ? currentUserObject.profile.company_size : '-' }}</p>

          <h5>Office Industry</h5>
          <p>{{ (currentUserObject.profile.industry) ?currentUserObject.profile.industry : '-' }}</p>

          <h5>Job Title</h5>
          <p>{{ (currentUserObject.profile.position) ? currentUserObject.profile.position : '-' }}</p>

          <h5>Job Level</h5>
          <p>{{ (currentUserObject.profile.job_level) ? currentUserObject.profile.job_level : '-' }}</p>
        </b-col>

        <b-col cols="12" v-if="currentUserObject.additional_registration_data.length > 0">
          <h4 class="mb-2 mt-1">Additional fields</h4>
          <div v-for="(currentData, index) in currentUserObject.additional_registration_data" :key="index">
            <h5>{{ currentData.field_name }}</h5>
            <p v-if="currentData.details.is_image == 0">{{ (currentData.value) ? currentData.value : '-' }}</p>            
            <p v-else><img :src="(currentData.value) ? currentData.value : ''" style="width:100%"></p>            
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="primary" @click="fullProfile(currentUserObject.user.id)">
          View User
          </b-button>
        </div>
      </template>


    </b-modal>

    <!-- Modals QR-->

    <b-modal
      id="modal-qr"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showQRlModal"
    >

     <template #modal-header="{}">
        <h5>QR Detail</h5>
      </template>

      <b-row>
        <b-col cols="12">
          <h5>Name</h5>
          <p>{{ currentQrObject.name }}</p>

          <h5>Phone</h5>
          <p>{{ currentQrObject.phone }}</p>

          <h5>Email</h5>
          <p>{{ currentQrObject.email }}</p>

          <h5>QRCode</h5>
          <p><center><qrcode-vue :value="currentQrObject.email" :size="300"></qrcode-vue></center></p>

        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
        <div style="float: right">
          <b-button size="md" variant="success" @click="sendWA(currentObject.event_email_id,currentObject.subject)">
          Send Via WhatsApp
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol, BButtonGroup, BFormFile, BAlert, BFormCheckbox, BBadge
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from "axios"
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    BFormFile,
    XLSX,
    BAlert,
    BFormCheckbox,
    BBadge,
    saveAs,
    JSZip,
    axios,
    QrcodeVue
  },
  data() {
    return {
      isLoading: false,
      filterValues: [
        {
          label: "All participants",
          value: "all"
        },
        {
          label: "Checked in participants",
          value: "checked_in"
        },
        {
          label: "Not yet checked in",
          value: "unchecked_in"
        },
        {
          label: "Verified participants",
          value: "verified"
        },
        {
          label: "Declined participants",
          value: "declined"
        },
        {
          label: "Pending participants",
          value: "pending"
        }
      ],
      zip: null,
      participantFilter: "all",
      users: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Company',
          field: 'profile.company'
        },
        {
          label: 'Salutation',
          field: 'salutation',
          // field: 'user.salutation',
        },
        {
          label: 'First Name',
          field: 'first_name',
          // field: 'user.name',
        },
        {
          label: 'Last Name',
          field: 'last_name',
          // field: 'user.last_name',
        },
        {
          label: 'Email',
          field: 'user.email'
        },
        {
          label: 'Phone',
          field: 'profile.phone'
        },
        {
          label: 'QR',
          field: 'qr_access',
        },
        {
          label: 'Transfer Proof',
          field: 'transfer_proofs',
        },
        {
          label: 'Register Origin',
          field: 'register_origin',
        },
        {
          label: 'Registered At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Status',
          field: 'verified_at_formatted',
        },
        {
          label: 'Paid At',
          field: 'paid_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Checked In At',
          field: 'checked_in_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Registered By',
          field: 'updated_by_user',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      allRows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      showDeleteModal: false,
      showDetailModal: false,
      showEditModal: false,
      showFormModal: false,
      showImportModal: false,
      showQRlModal: false,
      pageName: "Event Participant",

      currentObject: {
        user_id: -1,
        event_id: -1,
        event_name: null,
        event_code: null,
        additional_fields: null
      },

      currentQrObject: {
        id:-1,
        name: null,
        phone: null,
        email: null,
        event_code: null
      },

      currentUserObject: null,

      currentFields: null,
      importFile: null,

      importModal: {
        successMessage: null,
        errorMessages: null,

      },
    }
  },
  computed: {
  },
  methods: {
    selectionChanged(){
      // console.log("selected id: "+ this.$refs['participants'].selectedRows)
    },
    verifySelected(){
      const checkedData = this.$refs['participants'].selectedRows
      const totalSelected = checkedData.length
      var dataProcessed = 0
      var success = 0
      var failed = 0
      if(totalSelected == 0){
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Please select at least 1 data",
          },
        })
      }

      this.isLoadingTable = true

      checkedData.forEach((item, index) => {
        var id = item.id
        var name = item.user.salutation + " " + item.user.name + " " + item.user.last_name
        this.$http.put('/admin/v1/event_registers/' + id + '/verify', {})
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            failed++
          }else{
            success++
          }

          dataProcessed++
          if(dataProcessed == totalSelected){
            this.isLoadingTable = false
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: "("+success+") user(s) successfully verified and ("+failed+") user(s) failed to verified ",
              },
            })
          }
        })
      })

    },
    declineSelected(){
      const checkedData = this.$refs['participants'].selectedRows
      const totalSelected = checkedData.length
      var dataProcessed = 0
      var success = 0
      var failed = 0
      if(totalSelected == 0){
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Please select at least 1 data",
          },
        })
      }

      this.isLoadingTable = true

      checkedData.forEach((item, index) => {
        var id = item.id
        var name = item.user.salutation + " " + item.user.name + " " + item.user.last_name
        this.$http.put('/admin/v1/event_registers/' + id + '/decline', {})
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            failed++
          }else{
            success++
          }

          dataProcessed++
          if(dataProcessed == totalSelected){
            this.isLoadingTable = false
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: "("+success+") user(s) successfully declined and ("+failed+") user(s) failed to declined ",
              },
            })
          }
        })
      })
    },
    qrModal(rowData) {  
      this.currentQrObject.id = -1
      this.currentQrObject.name = null
      this.currentQrObject.phone = null
      this.currentQrObject.email = null
      this.currentQrObject.event_code = null

      this.currentQrObject.id = rowData.id
      this.currentQrObject.name = rowData.user.salutation+" "+rowData.user.name+" "+rowData.user.last_name
      this.currentQrObject.phone = rowData.profile.phone
      this.currentQrObject.email = rowData.user.email
      this.currentQrObject.event_code = rowData.code
      this.showQRlModal = true
    },
    sendWA(){
      const phoneNum = this.currentQrObject.phone.replace('(','').replace(')','').replace('+','')
      //const phoneNum = "6281295369456"
      const name = this.currentQrObject.name.replace(' ','%20')
      const urlWA = "https://wa.me/"+phoneNum+"?text=Hello%20"+name+",%20this%20is%20your%20QRCode%20for%20event:%0A"+this.currentObject.event_name+"%0A%0Ahttps://event.kimcommunication.com/access/"+this.currentObject.event_code+"/"+this.currentQrObject.email
      window.open(urlWA, '_blank');
      this.currentQrObject.id = -1
      this.currentQrObject.name = null
      this.currentQrObject.phone = null
      this.currentQrObject.email = null
      this.currentQrObject.event_code = null
      this.showQRlModal = false
    },
    verify(id, index) {
      this.isLoadingForm = true

      this.$http.put('/admin/v1/event_registers/' + id + '/verify', {})
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })

            this.isLoadingForm = false
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.rows[index].verified_at = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.rows[index].declined_at = null
          }

          this.isLoadingForm = false
      })
    },
    decline(id, index) {
      this.isLoadingForm = true

      this.$http.put('/admin/v1/event_registers/' + id + '/decline', {})
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })

            this.isLoadingForm = false
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.rows[index].verified_at = null
            this.rows[index].declined_at = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          }

          this.isLoadingForm = false
      })
    },
    statusVariant(verified_at, declined_at) {
      if(verified_at !== null) {
        return 'light-success'
      }
      else
      if(verified_at === null && declined_at !== null) {
        return 'light-danger'
      }
      else {
        return 'light-warning'
      }
    },
    labelVariant(verified_at, declined_at) {
      if(verified_at !== null) {
        return 'Verified'
      }
      else
      if(verified_at === null && declined_at !== null) {
        return 'Declined'
      }
      else {
        return 'Pending'
      }
    },
    defaultExcelHeaders() {
      return [
        "Company",
        "Brand Name",
        "Office Size",
        "Salutation",
        "First Name",
        "Last Name",
        "Job Title",
        "Job Level",
        "Home Address",
        "Office Address",
        "Office City",
        "Office Phone",
        "Mobile Phone",
        "Office Email",
        "Private Email",
        "Industry",
      ]
    },
    sampleExcel() {

      let excelHeaders = this.defaultExcelHeaders()

      this.currentObject.additional_fields.forEach((item, index) => {
        excelHeaders.push(this.currentObject.additional_fields[index].name)
      })

      const worksheet = XLSX.utils.json_to_sheet([])

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, "Example.xlsx", { compression: true });
    },
    submitExcel(defaultData, additionalData) {
      this.isLoadingForm = true

      this.importModal.errorMessages = []

      this.$http.post('/admin/v1/event_registers/batch', {
        event_id: this.currentObject.event_id,
        rows: defaultData,
        additional_fields: additionalData
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.importModal.errorMessages = []

            if(data.data.errors !== null) {
              data.data.errors.forEach((error, i) => {
                this.importModal.errorMessages.push(error)
              })
            }

            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

            this.showImportModal = false
          }
          
          this.isLoadingForm = false

          this.fetchData()
      })

      // this.isLoadingForm = false
    },
    saveImport() {
      this.excelToJSON(this.importFile)
    },
    excelToJSON(file) {
      this.isLoadingForm = true

      let excelHeaders = this.defaultExcelHeaders()

      var reader = new FileReader()

      reader.onload = (e) => {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary'
        })

        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          var rows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName], {defval: ""})

          let rowsFormatted = []

          let additionalAttributes = []

          if(rows.length == 0) {
            const errorMessage = "The excel file can't be empty"

            if(this.importModal.errorMessages === null) {
              this.importModal.errorMessages = []
            }

            this.importModal.errorMessages.push(errorMessage)
          }
          else {
            const indices = Object.keys(rows[0])

            rows.forEach((row, i) => {
              let submitCustomAttributes = false

              let formattedObject = {}

              let customAttributes = {}

              // console.log(row)

              indices.forEach((currentIndex, k) => {
                let formattedIndex = ''

                if(this.defaultExcelHeaders().includes(currentIndex)) {
                  formattedIndex = currentIndex.toLowerCase()

                  formattedIndex = formattedIndex.replace(/ /g, "_");
                  
                  if(row[currentIndex] == "") {
                    formattedObject[formattedIndex] = null
                  }
                  else {
                    formattedObject[formattedIndex] = row[currentIndex]
                  }

                }
                else 
                {
                  formattedIndex = currentIndex

                  if(row[currentIndex] == "") {
                    customAttributes[formattedIndex] = null
                  }
                  else {
                    customAttributes[formattedIndex] = row[currentIndex]
                  }

                  // console.log(currentIndex, row[currentIndex], customAttributes[formattedIndex])

                  submitCustomAttributes = true
                }
              })

              rowsFormatted.push(formattedObject)

              if(submitCustomAttributes) {
                additionalAttributes.push(customAttributes)
              }
            })

            this.submitExcel(rowsFormatted, additionalAttributes)
          }
        })
      }

      reader.onerror = (ex) => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Error`,
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: "Error processing excel file",
          },
        })

        this.isLoadingForm = false
      };

      reader.readAsBinaryString(file);
    },
    download(url) {
      let filename  = ''

      const urlSplit = url.split("/")

      if(urlSplit.length > 0) {
        filename = urlSplit[urlSplit.length - 1]

        filename = filename.replace("%40", "_")

        return axios.get(url, { responseType: "blob" }).then((resp) => {
          this.zip.file(filename, resp.data) 
        })
      }
      else {
        return false
      }
    },
    downloadPDF(url,name) {
      let filename  = ''

      const urlSplit = url.split("/")

      if(urlSplit.length > 0) {
        filename = urlSplit[urlSplit.length - 1]

        filename = filename.replace("%40", "_")

        return axios.get(url, { responseType: "blob" }).then((resp) => {
          const href = window.URL.createObjectURL(resp.data);
          const anchorElement = document.createElement('a');
          anchorElement.href = href;
          anchorElement.download = name+".jpg";
          document.body.appendChild(anchorElement);
          anchorElement.click();
          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);
        })
      }
      else {
        return false
      }
    },
    downloadQR() {
      this.isLoading = true
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/event_registers/' + this.currentObject.event_id + '/download_qr')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })

            this.isLoading = false
            this.isLoadingTable = false
          }
          else {
            const currentData = res.data.data

            if(currentData.urls.length == 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: "Sorry, there are no participants",
                },
              })

              this.isLoading = false
              this.isLoadingTable = false
            }
            else {
              const files = currentData.urls.map((item) => this.download(item))

              this.isLoading = true
              this.isLoadingTable = true

              Promise.all(files)
                .then(() => {
                    //when all promises resolved - save zip file
                    this.zip.generateAsync({ type: "blob" }).then(function (blob) {
                        saveAs(blob, "qr_codes.zip")
                    })

                    this.isLoading = false
                    this.isLoadingTable = false
                })
                .catch((err) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: "Error when downloading files: " + err 
                    },
                  })

                  this.isLoading = false
                  this.isLoadingTable = false
                })
            }
          }
     })

    },
    downloadNameTags() {
      this.isLoading = true
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/event_registers/' + this.currentObject.event_id + '/download_prints')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })

            this.isLoading = false
            this.isLoadingTable = false
          }
          else {
            const currentData = res.data.data

            if(currentData.urls.length == 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: "Sorry, there are no participants",
                },
              })

              this.isLoading = false
              this.isLoadingTable = false
            }
            else {
              const files = currentData.urls.map((item) => this.download(item))

              this.isLoading = true
              this.isLoadingTable = true

              Promise.all(files)
                .then(() => {
                    //when all promises resolved - save zip file
                    this.zip.generateAsync({ type: "blob" }).then(function (blob) {
                        saveAs(blob, "nametags.zip")
                    })

                    this.isLoading = false
                    this.isLoadingTable = false
                })
                .catch((err) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: "Error when downloading files: " + err 
                    },
                  })

                  this.isLoading = false
                  this.isLoadingTable = false
                })
            }
          }
     })
    },
    downloadNametagSingle(row) {
      this.isLoading = false
      this.isLoadingTable = false

      this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Info`,
                icon: 'CoffeeIcon',
                variant: 'warning',
                text: "Downloading Nametag for "+ row.user.name+" "+row.user.last_name,
              }})

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/event_registers/' + row.id + '/download_prints_single')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })

            this.isLoading = false
            this.isLoadingTable = false
          }
          else {
            const currentData = res.data.data

            if(currentData.urls.length == 0) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: "Sorry, there are no participants",
                },
              })

              this.isLoading = false
              this.isLoadingTable = false
            }
            else {
             
              currentData.urls.map((item) => this.downloadPDF(item,row.user.name+"_"+row.user.last_name+"_"+row.profile.company))

              this.isLoading = false
              this.isLoadingTable = false

            //   Promise.all(files)
            //     .then(() => {
            //         //when all promises resolved - save zip file
            //         this.zip.generateAsync({ type: "blob" }).then(function (blob) {
            //             saveAs(blob, "nametags.jpg")
            //         })

            //         this.isLoading = false
            //         this.isLoadingTable = false
            //     })
            //     .catch((err) => {
            //       this.$toast({
            //         component: ToastificationContent,
            //         position: 'top-right',
            //         props: {
            //           title: `Error`,
            //           icon: 'CoffeeIcon',
            //           variant: 'danger',
            //           text: "Error when downloading files: " + err 
            //         },
            //       })

            //       this.isLoading = false
            //       this.isLoadingTable = false
            //     })
            }
          }
     })
    },
    downloadExcel() {
      this.isLoading = true

      let excelRows = []

      this.rows.map(row => {

          let currentObj = {}

          currentObj = {
            company: (row.profile) ? row.profile.company : '-',
            brand_name: (row.profile) ? row.profile.brand_name : '-',
            salutation: (row.user_profile) ? row.user_profile.registered_salutation: row.user.salutation,
            first_name: (row.user_profile) ? row.user_profile.registered_name : row.user.name ,
            last_name: (row.user_profile) ? row.user_profile.registered_last_name : row.user.last_name,
            position: (row.profile) ? row.profile.position : '-',
            job_level: (row.profile) ? row.profile.job_level : '-',
            company_address: (row.profile) ? row.profile.company_address : '-',
            company_city: (row.profile && row.profile.company_city) ? row.profile.company_city.name : '-',
            company_state: (row.profile  && row.profile.company_city && row.profile.company_city.state) ? row.profile.company_city.state.name : '-',
            company_country: (row.profile  && row.profile.company_city && row.profile.company_city.state && row.profile.company_city.state.country) ? row.profile.company_city.state.country.name : '-',
            home_address: (row.profile) ? row.profile.home_address : '-',
            company_phone: (row.profile) ? row.profile.company_phone : '-',
            phone: (row.profile) ? row.profile.phone : '-',
            company_email: (row.profile) ? row.profile.company_email : '-',
            email:  (row.user) ? row.user.email : '-',
            industry: (row.profile) ? row.profile.industry : '-',
            company_size: (row.profile) ? row.profile.company_size : '-',
            // personal_email: (row.profile) ? row.profile.personal_email : '-',
            checked_in_at: (row.checked_in_at) ? this.formatDateReverse(row.checked_in_at) : '-',
            verified_at: (row.verified_at) ? this.formatDateReverse(row.verified_at) : '-',
            declined_at: (row.declined_at) ? this.formatDateReverse(row.declined_at) : '-',
            registered_at: (row.created_at),
            registered_by: (row.updated_by) ? row.updated_by.name : row.created_by.name, 
            registration_origin: (row.register_origin) ? row.register_origin : '-'
          }

        row.additional_registration_data.map(data => {
          currentObj[data.field_name] = (data.value) ? data.value : '-'
        })

        excelRows.push(currentObj)

      })

      excelRows.sort(function (a, b) {return  new Date(a.registered_at) - new Date(b.registered_at)});

      let excelHeaders = []

      excelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            excelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            excelRows[index][key] = '-'
          }
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(excelRows)

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, this.pageName+"_"+this.currentObject.event_name  + "_" + Date.now() + ".xlsx", { compression: true });

      this.isLoading = false
    },
    saveAdditionalData() {
      this.isLoadingForm = true

      this.$http.put('/admin/v1/events/' + this.currentObject.event_id + '/registers', {
        user_id: this.currentUserObject.user_id,
        additional_fields: this.currentUserObject.additional_registration_data
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showEditModal = false

          this.isLoadingForm = false

          this.fetchData()
      })
    },
    onFilterChange(value) {
      if(value == "all") {
        this.rows = []

        this.allRows.forEach((item) => {
          this.rows.push(item)
        })
      }
      else
      if(value == "checked_in") {
        this.rows = this.allRows.filter((item) => {
          return (item.checked_in_at !== null)
        })
      }
      else
      if(value == "unchecked_in") {
        this.rows = this.allRows.filter((item) => {
          return (item.checked_in_at === null)
        })
      }
      else
      if(value == "verified") {
        this.rows = this.allRows.filter((item) => {
          return (item.verified_at !== null)
        })
      }
      else
      if(value == "declined") {
        this.rows = this.allRows.filter((item) => {
          return (item.declined_at !== null)
        })
      }
      else
      if(value == "pending") {
        this.rows = this.allRows.filter((item) => {
          return (item.verified_at === null && item.declined_at === null)
        })
      }
    },
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY HH:mm')
    },
    viewAllEvents() {
      this.$router.push('/events')
    },
    save() {
      this.$http.post('/admin/v1/events/' + this.currentObject.event_id + '/registers', {
        user_id: this.currentObject.user_id,
        additional_fields: this.currentObject.additional_fields
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showFormModal = false

          this.isLoadingForm = false

          this.fetchData()
      })
    },
    goUser(user_id) {
      this.$router.push('/users/' + user_id + '/view')

    },
    add() {
      this.fetchUsers()

      this.showFormModal = true

      this.currentObject.user_id = null
      this.currentObject.event_id = router.currentRoute.params.id

      this.currentObject.additional_fields.forEach((item, index) => {
        this.currentObject.additional_fields[index].value = null
      })
    },
    importExcel() {
      this.importModal = {
        successMessage: null,
        errorMessages: null
      }
      
      this.showImportModal = true

      this.currentObject.user_id = null
      this.currentObject.event_id = router.currentRoute.params.id
    },
    fullProfile(user_id) {
      this.$router.push('/users/' + user_id + '/view')
    },
    editModal(user) {
      this.showEditModal = true

      this.currentUserObject = user
    },
    detailModal(user) {
      this.showDetailModal = true
      this.currentUserObject = null
      this.currentUserObject = user
    },
    removeModal(user_id) {      
      this.showDeleteModal = true

      this.currentObject.user_id = user_id
      this.currentObject.event_id = router.currentRoute.params.id

    },
    remove() {
      this.isLoadingDelete = true

      this.$http.post('/admin/v1/event_registers/remove', {
        user_id: this.currentObject.user_id,
        event_id: this.currentObject.event_id
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchEvent() {
      this.isLoadingForm = true

      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
            this.currentObject.event_code =  currentData.code

            this.currentFields = []
            this.currentObject.additional_fields = []

            if(currentData.fields !== null) {
              currentData.fields.forEach((item) => {
                this.currentFields.push(item)

                item['value'] = null

                this.currentObject.additional_fields.push(item)
              })
            }
          }

          this.isLoadingForm = false
     })
    },
    fetchData() {
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/events/' + this.currentObject.event_id + '/registers')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.allRows = currentData
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    fetchUsers() {
      this.isLoadingForm = true

      this.$http.get('/admin/v1/users_verified')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.users = []

            const currentData = res.data.data

            currentData.forEach((value, index) => {
              const name = value.salutation + ". " + value.name + " " + value.last_name

              this.users.push({
                label: value.email + " (" + name + ")",
                value: value.id
              })
            })
          }

          this.isLoadingForm = false

     })
    },
    isValid() {
      return this.currentObject.user_id !== null && this.currentObject.user_id != ''
    }
  },
  created() {
    this.zip = new JSZip()

    this.fetchData()
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.participant-filter .v-select.vs--single.vs--searchable {
  width: 240px;
}

.event-participant-table .vgt-responsive {
  min-height: 350px;
}
</style>
